import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './services/api.service';
import { ApiModule } from './services/api/api.module';
import { AuthService } from './services/api/services';
import { ApiAuthInterceptor } from './services/auth/api-auth.interceptor';
import { CoreProvider } from './services/core';
import { DateService } from './services/date.service';

class CustomTranslateHttpLoader extends TranslateHttpLoader {
  getTranslation(lang: string) {
    return super.getTranslation(lang).pipe(map(res => {
      for (const key in res) {
        if (res[key] === '') {
          if (environment.production) {
            delete res[key];
          } else {
            console.warn('Empty translation for key:', key);
          }
        }
      }
      return res;
    }));
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (!params.translateService.translations[params.translateService.currentLang][params.key]) {
      // Translation file not loaded, return the key
      return params.key;
    }
    const fallback = params.key;
    return params.translateService.translations[params.translateService.currentLang][params.key] || fallback;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    IonicModule.forRoot(),
    ApiModule.forRoot({rootUrl:environment.endpoint}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    })
  ],
  providers: [
    DateService,
    ApiService,
    AuthService,
    CoreProvider,
    { provide: HTTP_INTERCEPTORS, useClass: ApiAuthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
