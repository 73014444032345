import { ApplicationRef, Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/internal/operators/first';
import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';

@Injectable({ providedIn: 'root' })
export class CoreProvider {

  public stable = false;
  public appIsStable = this.appRef.isStable.pipe(first(isStable => {
    if (isStable === true) {
      this.stable = true;
      return true;
    } else {
      return false;
    }
  }));
  constructor(
    public api: ApiService,
    public auth: AuthService,
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    private loading: LoadingController,
    private appRef: ApplicationRef,
    public modalCtrl: ModalController,
    public translate: TranslateService
  ) {
    this.auth.init(this);
    translate.setDefaultLang('en'); // Set default language
    translate.use(document.documentElement.lang = this.detectLanguage()); // Use browser language
  }

  private detectLanguage(): string {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|es/) ? browserLang : 'en';
  }

  public get user() {
    return this.auth.user || undefined;
  };
  public get isLoggedIn() { return this.auth.token!==''; };


  public async createLoading(text=null, noPresent=false) {
    const loading = await this.loading.create({message: text});
    if (!noPresent) {await loading.present();}
    return loading;
  }

  public async successToast(loading=null, message=null) {
    if (loading) {loading.dismiss();}
    await (await this.toastCtrl.create({
      message: message||'Se ha completado la acción correctamente',
      duration: 3000,
      color: 'success'
    })).present();
  }

  public async errorToast(loading=null, message: any|string=null, buttons=null, duration=null) {
    if (loading) {loading.dismiss();}

    if (typeof message != 'string' && message!=null) {
      if (message.status && message.status===401 && this.isLoggedIn) {
        this.auth.logout(()=>this.navCtrl.navigateRoot('/login?msg=expired'));
      }
      message = this.fetchErrMsg(message.error);
    }

    return await (await this.toastCtrl.create({
      message: message||'Se ha producido un error, inténtelo de nuevo más tarde',
      duration: duration||5000,
      color: 'danger',
      buttons: buttons||[{text:'OK', role:'cancel'}]
    })).present();
  }
  public fetchErrMsg(err) {
    if (err.error) {return err.error;}
    if (err.errors) {
      let msg = 'Se han producido los siguientes errores: ';
      // eslint-disable-next-line guard-for-in
      for (const itm in err.errors) {
        msg += '\n- '+err.errors[itm].toString();
      }
      return msg;
    }
    if (Object.getPrototypeOf(err).toString().indexOf('ProgressEvent')) {
      return 'Se ha producido un error de comunicación, por favor, compruebe su conexión.';
    }
  }

}
