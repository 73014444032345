import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  public todayDate;
  public dateAdded;
  public dateSubtracted;
  public yesterday;

  constructor(
  ) { }

  public getDates(){
    const today = new Date();
    this.todayDate = formatDate(today, 'yyyy-MM-dd', 'en');
    const milisecondAdd = new Date().setFullYear(today.getFullYear() + 100);
    const newDate = new Date(milisecondAdd);
    const milisecondSub = new Date().setFullYear(today.getFullYear() - 100);
    const newSub = new Date(milisecondSub);
    this.dateAdded = formatDate(newDate, 'yyyy-MM-dd', 'en');
    this.dateSubtracted = formatDate(newSub, 'yyy-MM-dd', 'en')
    this.yesterday = formatDate(new Date(today.setDate(today.getDate() - 1)), 'yyyy-MM-dd', 'en');
  }

}
