import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { CoreProvider } from './services/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Registro', url: '/auth/register', icon: 'person' },
    { title: 'Login', url: '/auth/login', icon: 'person-add' },
    { title: 'Mi cuenta', url: '/private/my-account', icon: 'person-circle' },
    { title: 'Administración', url: '/private/admin', icon: 'lock-closed' },
    { title: 'Circuito', url: '/circuit', icon: 'map' },
    { title: 'Normas', url: '/rules', icon: 'book' },
    { title: 'Contacto', url: '/contact', icon: 'mail' },
  ];


  constructor(private menuController: MenuController, public core: CoreProvider) {
  }

  public closeMenu(){
    this.menuController.close();
  }

  public logOut(){
    this.core.auth.logout()
  }
}
