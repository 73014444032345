/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserDto } from '../models/user-dto';
import { UserGetAllReq } from '../models/user-get-all-req';
import { UserListDto } from '../models/user-list-dto';
import { UserPutDto } from '../models/user-put-dto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userList
   */
  static readonly UserListPath = '/user/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userList$Response(params: {

    /**
     * pagination load page
     */
    page?: number;
    body: UserGetAllReq
  }): Observable<StrictHttpResponse<UserListDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserListPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userList(params: {

    /**
     * pagination load page
     */
    page?: number;
    body: UserGetAllReq
  }): Observable<UserListDto> {

    return this.userList$Response(params).pipe(
      map((r: StrictHttpResponse<UserListDto>) => r.body as UserListDto)
    );
  }

  /**
   * Path part for operation userGet
   */
  static readonly UserGetPath = '/user/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Response(params: {

    /**
     * uuid to fetch
     */
    uuid: string;
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserGetPath, 'get');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet(params: {

    /**
     * uuid to fetch
     */
    uuid: string;
  }): Observable<UserDto> {

    return this.userGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation deleteUser
   */
  static readonly DeleteUserPath = '/user/{uuid}';

  /**
   * Delete the desired user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: {

    /**
     * user uuid
     */
    uuid: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeleteUserPath, 'delete');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete the desired user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: {

    /**
     * user uuid
     */
    uuid: string;
  }): Observable<void> {

    return this.deleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userPut
   */
  static readonly UserPutPath = '/user';

  /**
   * Put
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPut$Response(params: {
    body: UserPutDto
  }): Observable<StrictHttpResponse<{
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserPutPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * Put
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userPut(params: {
    body: UserPutDto
  }): Observable<{
'message'?: string;
}> {

    return this.userPut$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>) => r.body as {
'message'?: string;
})
    );
  }

}
