/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthMeResp, LoginReq, TokenRes } from '../api/models';
import { CoreProvider } from '../core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private data: {
    user: AuthMeResp;
    token: TokenRes;
  } = { user: null, token: null };
  private initedData = false;
  private core: CoreProvider;

  constructor() {}

  public get inited(): boolean {
    return this.initedData;
  }
  public get user() {
    return this.data.user;
  }
  public get token() {
    return environment.authToken ? 'Bearer ' + environment.authToken : '';
  }

  public init = (core: CoreProvider) => {
    this.core = core;
    this.initChecks();
  };

  public login(
    data: LoginReq,
    cbSuccess: Function = null,
    cbErr: Function = null
  ) {
    const handleErr = (err) => {
      if (cbErr) {
        cbErr(err);
      } else {
        this.core.errorToast();
        console.error('Error in login request', err);
      }
    };

    this.core.api.auth.authLogin({ body: data }).subscribe(
      (sess) => {
        this.data.token = sess;
        environment.authToken = sess.access_token;
        this.core.api.auth.authMe().subscribe(
          (user) => {
            this.data.user = user;

            this.updateStorage();

            if (cbSuccess) {
              cbSuccess();
            }
          },
          (err) => handleErr(err)
        );
      },
      (err) => handleErr(err)
    );
  }

  public logout(cb: Function = null) {
    this.core.api.auth.authLogout().subscribe(
      () => {
        this.data = { user: null, token: null };
        environment.authToken = null;
        this.updateStorage();
        if (cb) {
          cb();
        }
      },
      (err) => {
        if (err.status === 401) {
          // Handle already invalid session
          this.data = { user: null, token: null };
          environment.authToken = null;
          this.updateStorage();
          if (cb) {
            cb();
          }
        } else {
          this.core.errorToast(null, err);
        }
      }
    );
  }

  updateStorage() {
    localStorage.setItem('session', JSON.stringify(this.data));
  }

  private initChecks() {
    //TODO: Add token refresh every hour since last refresh/login
    const sess = JSON.parse(localStorage.getItem('session'));
    if (sess && sess.token) {
      this.data = sess;
      environment.authToken = this.data.token.access_token;
    }

    if (this.token) {
      this.core.api.auth.authMe().subscribe(
        (user) => {
          if (!this.initedData) {
            this.initedData = true;
          }
          this.data.user = user;

          this.updateStorage();
        },
        () => {
          if (!this.initedData) {
            this.initedData = true;
          }
          this.data = { user: null, token: null };
          environment.authToken = null;
          this.updateStorage();
          // this.core.errorToast(
          //   null,
          //   'Su sesión anterior ha sido cerrada por seguridad',
          //   15000
          // );
        }
      );
    } else {
      if (!this.initedData) {
        this.initedData = true;
      }
    }
  }

}
