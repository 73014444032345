/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthMeResp } from '../models/auth-me-resp';
import { AuthPasswordRecoverDone } from '../models/auth-password-recover-done';
import { LoginReq } from '../models/login-req';
import { TokenRes } from '../models/token-res';
import { UserRegisterDto } from '../models/user-register-dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authLogin
   */
  static readonly AuthLoginPath = '/auth/login';

  /**
   * Logueo
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authLogin$Response(params: {
    body: LoginReq
  }): Observable<StrictHttpResponse<TokenRes>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenRes>;
      })
    );
  }

  /**
   * Logueo
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authLogin(params: {
    body: LoginReq
  }): Observable<TokenRes> {

    return this.authLogin$Response(params).pipe(
      map((r: StrictHttpResponse<TokenRes>) => r.body as TokenRes)
    );
  }

  /**
   * Path part for operation authRegister
   */
  static readonly AuthRegisterPath = '/auth/register';

  /**
   * Register
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authRegister$Response(params: {
    body: UserRegisterDto
  }): Observable<StrictHttpResponse<{
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthRegisterPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * Register
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authRegister(params: {
    body: UserRegisterDto
  }): Observable<{
'message'?: string;
}> {

    return this.authRegister$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>) => r.body as {
'message'?: string;
})
    );
  }

  /**
   * Path part for operation authCheckEmail
   */
  static readonly AuthCheckEmailPath = '/auth/validate-email/{email}';

  /**
   * Check if email exists
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authCheckEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  authCheckEmail$Response(params: {

    /**
     * email to validate
     */
    email: string;
  }): Observable<StrictHttpResponse<{
'available': boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthCheckEmailPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'available': boolean;
        }>;
      })
    );
  }

  /**
   * Check if email exists
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authCheckEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authCheckEmail(params: {

    /**
     * email to validate
     */
    email: string;
  }): Observable<{
'available': boolean;
}> {

    return this.authCheckEmail$Response(params).pipe(
      map((r: StrictHttpResponse<{
'available': boolean;
}>) => r.body as {
'available': boolean;
})
    );
  }

  /**
   * Path part for operation authHashVerify
   */
  static readonly AuthHashVerifyPath = '/auth/verified-hash/{hash}';

  /**
   * Hash validation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authHashVerify()` instead.
   *
   * This method doesn't expect any request body.
   */
  authHashVerify$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthHashVerifyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Hash validation
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authHashVerify$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authHashVerify(params?: {
  }): Observable<void> {

    return this.authHashVerify$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authPasswordRecover
   */
  static readonly AuthPasswordRecoverPath = '/auth/recovery-password';

  /**
   * Recuperación de contraseña
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authPasswordRecover()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authPasswordRecover$Response(params: {
    body: {
'email'?: string;
}
  }): Observable<StrictHttpResponse<AuthPasswordRecoverDone>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthPasswordRecoverPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthPasswordRecoverDone>;
      })
    );
  }

  /**
   * Recuperación de contraseña
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authPasswordRecover$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authPasswordRecover(params: {
    body: {
'email'?: string;
}
  }): Observable<AuthPasswordRecoverDone> {

    return this.authPasswordRecover$Response(params).pipe(
      map((r: StrictHttpResponse<AuthPasswordRecoverDone>) => r.body as AuthPasswordRecoverDone)
    );
  }

  /**
   * Path part for operation authPasswordChange
   */
  static readonly AuthPasswordChangePath = '/auth/recovery-hash';

  /**
   * Recuperación de contraseña
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authPasswordChange()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authPasswordChange$Response(params: {
    body: {
'hash'?: string;
'password'?: string;
'password_confirm'?: string;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthPasswordChangePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Recuperación de contraseña
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authPasswordChange$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authPasswordChange(params: {
    body: {
'hash'?: string;
'password'?: string;
'password_confirm'?: string;
}
  }): Observable<void> {

    return this.authPasswordChange$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authMe
   */
  static readonly AuthMePath = '/auth/me';

  /**
   * About me
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  authMe$Response(params?: {
  }): Observable<StrictHttpResponse<AuthMeResp>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthMePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthMeResp>;
      })
    );
  }

  /**
   * About me
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authMe(params?: {
  }): Observable<AuthMeResp> {

    return this.authMe$Response(params).pipe(
      map((r: StrictHttpResponse<AuthMeResp>) => r.body as AuthMeResp)
    );
  }

  /**
   * Path part for operation authLogout
   */
  static readonly AuthLogoutPath = '/auth/logout';

  /**
   * Desloguear mi usuario
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authLogout$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthLogoutPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Desloguear mi usuario
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authLogout(params?: {
  }): Observable<void> {

    return this.authLogout$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authRefresh
   */
  static readonly AuthRefreshPath = '/auth/refresh';

  /**
   * Update token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  authRefresh$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthRefreshPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authRefresh(params?: {
  }): Observable<void> {

    return this.authRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
