import { Injectable } from '@angular/core';
import { AuthService, UserService, UserVisitService, VisitService } from './api/services';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public auth: AuthService,
    public user: UserService,
    public visit: VisitService,
    public userVisit: UserVisitService,
  ) { }

}
