/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MyVisitListDto } from '../models/my-visit-list-dto';
import { VisitCreateDto } from '../models/visit-create-dto';

@Injectable({
  providedIn: 'root',
})
export class UserVisitService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation myVisitList
   */
  static readonly MyVisitListPath = '/my/visit/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myVisitList()` instead.
   *
   * This method doesn't expect any request body.
   */
  myVisitList$Response(params?: {

    /**
     * pagination load page
     */
    page?: number;
  }): Observable<StrictHttpResponse<MyVisitListDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserVisitService.MyVisitListPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MyVisitListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myVisitList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myVisitList(params?: {

    /**
     * pagination load page
     */
    page?: number;
  }): Observable<MyVisitListDto> {

    return this.myVisitList$Response(params).pipe(
      map((r: StrictHttpResponse<MyVisitListDto>) => r.body as MyVisitListDto)
    );
  }

  /**
   * Path part for operation myVisitPut
   */
  static readonly MyVisitPutPath = '/my/visit/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myVisitPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myVisitPut$Response(params: {

    /**
     * uuid to fetch
     */
    uuid: string;
    body: VisitCreateDto
  }): Observable<StrictHttpResponse<{
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserVisitService.MyVisitPutPath, 'patch');
    if (params) {
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myVisitPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myVisitPut(params: {

    /**
     * uuid to fetch
     */
    uuid: string;
    body: VisitCreateDto
  }): Observable<{
'message'?: string;
}> {

    return this.myVisitPut$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>) => r.body as {
'message'?: string;
})
    );
  }

  /**
   * Path part for operation myVisitPost
   */
  static readonly MyVisitPostPath = '/my/visit';

  /**
   * Put
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myVisitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myVisitPost$Response(params: {
    body: VisitCreateDto
  }): Observable<StrictHttpResponse<{
'message'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserVisitService.MyVisitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message'?: string;
        }>;
      })
    );
  }

  /**
   * Put
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myVisitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myVisitPost(params: {
    body: VisitCreateDto
  }): Observable<{
'message'?: string;
}> {

    return this.myVisitPost$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>) => r.body as {
'message'?: string;
})
    );
  }

}
